import React from "react";
import defaultProfile from "../../Assets/default-profile.png";

const Avatar = ({ image }) => {
  console.log("image", image);
  return (
    <img
      className="w-[30px] h-[30px] rounded-full"
      src={ image?.startsWith("https://") ? image : image !== "" ? process.env.REACT_APP_IMAGE_URL.concat(image) : defaultProfile}
      alt="avatar"
    />
  );
};

export default Avatar;
